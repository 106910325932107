const React = require("react");
const moment = require("moment");

import { isEmpty } from "lodash";
import APIWrapper from "./APIWrapper";
import Booking from "./Booking";
import styles from "../styles/appointmentSlot";
import BookedAppointment from "./Booked";
import { pick } from "../utilities/helpers";
import withContext from "../utilities/withContext";

const APPOINTMENT_STATUS_LOOKUP = {
  checked_in: "Checked in",
  cancelled: "Cancelled",
  booked: "Booked"
};

const PMP_ARRIVED_STATUSES = ["Arrived", "Checked In", "In Room", "In Session", "Complete"];

class AppointmentSlot extends React.Component {
  constructor(props) {
    super(props);
    this.renderChildren = this.renderChildren.bind(this);
    this.handleNewAppointmentBooking = this.props.handleNewAppointmentBooking.bind(
      this
    );
    this.state = {
      showBookingModal: false,
      bookedCustomer: null,
      isCheckedIn: this.getCheckedInStatus(),
      activeAppointment: this.props.appointment ? this.props.appointment : {},
    };
    this.timer = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  setBookedCustomer = customer => {
    const { currentAppointmentType } = this.props;
    const strippedAppointmentType = currentAppointmentType.toLowerCase().replace("comprehensive", " ");
    this.setState({
      bookedCustomer: {
        ...customer,
        currentAppointmentType: strippedAppointmentType,
      },
      appointmentCreated: true,
    });
  };

  confirmBooking = () => {
    this.setState({
      showConfirmation: true
    });

    // do not show message "Booked!" after 10 seconds
    this.timer = setTimeout(() => {
      this.setState({
        showConfirmation: false
      });
    }, 10000)
  };

  getCheckedInStatus() {
    const { appointment } = this.props;
    if (!appointment) return false;

    return PMP_ARRIVED_STATUSES.includes(appointment.status);
  }

  formatTime(ISOTime) {
    return moment(ISOTime).format("LT");
  }

  getDuration(startTime, endTime) {
    const start = moment(startTime);
    const end = moment(endTime);
    const duration = moment.duration(end.diff(start));
    return duration.minutes();
  }

  isExamTypeSelected = () => {
    const { currentAppointmentType } = this.props;
    return !(currentAppointmentType === null || currentAppointmentType === "Please select an exam type");
  };

  handleBookClick() {
    const { reminderListener } = this.props;
    logger.info({ component: "AppointmentSlot" }, "Clicked to book appointment");
    if (!this.isExamTypeSelected()) {
      reminderListener();
    } else {
      this.setState({
        showBookingModal: true,
        customerCreated: false,
        appointmentCreated: false,
      });
    }
  }

  handleBookingModalClose() {
    logger.info({ component: "AppointmentSlot" }, "Clicked to close booking modal");
    this.setState({
      showBookingModal: false,
      appointmentBookingError: null,
      customerCreated: false,
      appointmentCreated: false,
    });
  }

  renderChildren({ data, err, isFetching, fetchData }) {
    const { isLoading } = this.props;
    const buttonStyles = this.isExamTypeSelected() ? { ...styles.bookButton }: { ...styles.disabledBookButton };
    const isEmptySlot = isEmpty(this.props.appointment);

    if (!isEmptySlot && !isLoading) {
      const { appointment } = this.props;
      logger.info({ component: "AppointmentSlot" }, "Showing appointments");
      return (
        <BookedAppointment
          {...{ appointment, fetchData, bookedCustomer: this.state.bookedCustomer }}
          {...pick(this.state, "isCheckedIn", "showConfirmation")}
          {...pick(this.props, "start_time", "facilityName", "nearby")}
          key={`${this.props.facilityShortname}_${this.props.start_time}`}
        />
      );
    } else if (this.state.showBookingModal && !isLoading) {
      logger.info({ component: "AppointmentSlot" }, "Showing booking modal");
      return (
        <Booking
          token={this.props.token}
          error={this.state.appointmentBookingError}
          startTime={this.props.start_time}
          duration={this.getDuration(
            this.props.start_time,
            this.props.end_time
          )}
          exam_room={this.props.exam_room}
          profile={this.props.profile}
          duration={this.props.duration}
          doctor={this.props.doctor}
          facilityShortname={this.props.facilityShortname}
          handleBookingModalClose={this.handleBookingModalClose.bind(this)}
          handleNewAppointmentBooking={this.handleNewAppointmentBooking}
          setBookedCustomer={this.setBookedCustomer.bind(this)}
          confirmBooking={this.confirmBooking}
          appointmentCreated={this.state.appointmentCreated}
          setExamTypeDropdown={this.props.setExamTypeDropdown}
        />
      );
    } else {
      return (
        <tr {...styles.block}>
          <td
            {...styles.cell}
            {...styles.cellTime}
            children={this.formatTime(this.props.start_time)}
          />
          <td
            {...styles.cell}
            children={this.props.nearby ? this.props.facilityName : " - "}
          />
          <td {...styles.cell} children={" - "} />
          <td {...styles.cell}>
            <button
              children="Book"
              onClick={this.handleBookClick.bind(this)}
              {...buttonStyles}
            />
          </td>
        </tr>
      );
    }
  }

  render() {
    const url = this.props.appointment
      ? this.props.appointment.update_status_url
      : "";

    return (
      <APIWrapper
        url={url}
        childFn={this.renderChildren}
        fetchOnMount={false}
      />
    );
  }
}

export default withContext(AppointmentSlot);
