const React = require("react");
const { render } = require("react-dom");
const moment = require("moment");

import { orderBy } from "lodash";

import AppointmentSlot from "./AppointmentSlot";
import APIWrapper from "./APIWrapper";
import styles from "../styles/nearbyEyeExamList";

import withContext from "../utilities/withContext";

class NearbyEyeExamList extends React.Component {
  handleNewAppointmentBooking(data) {
    if (data.error) {
      this.setState({ appointmentBookingError: data.error });
    } else {
      this.setState({ activeAppointment: data, showConfirmation: true });
    }
  }
  renderSection(section, date) {
    const orderedSection = orderBy(section, "timestamp");
    return (
      <div key={date} {...styles.block}>
        <h1 children={moment(date).format("dddd MMMM Do")} {...styles.date} />
        <table {...styles.table}>
          <thead>
            <tr {...styles.topLineWrapper}>
              <th {...styles.label} {...styles.labelTime} children={"TIME"} />
              <th {...styles.label}> LOCATION </th>
              <th {...styles.label}> CUSTOMER </th>
              <th {...styles.label}> STATUS </th>
            </tr>
          </thead>
          <tbody {...styles.sectionWrapper}>
            {orderedSection.map((slot, i) => {
              return (
                <AppointmentSlot
                 {...slot}
                  nearby={true}
                  facilityName={slot.facility_name}
                  facilityShortname={slot.facility_short_name}
                  key={`${slot.facility_short_name}__${slot.timestamp}`}
                  start_time={slot.timestamp}
                  end_time={moment(slot)
                    .add(slot.duration || 20, "minutes")
                    .toDate()}
                  handleNewAppointmentBooking={this.handleNewAppointmentBooking}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  render() {
    const exams = this.props.exams || {};
    const orderedKeys = orderBy(Object.keys(exams));
    const sections = orderedKeys.map(key => {
      return this.renderSection(exams[key], key);
    });
    return (
      <div>
        <h1 {...styles.title}> Upcoming appointments </h1>
        <div {...styles.closeButtonWrapper}>
          <button
            children={"CLOSE"}
            {...styles.closeButton}
            onClick={this.props.handleModalClose}
          />
        </div>
        <div children={sections} />
      </div>
    );
  }
}

export default withContext(NearbyEyeExamList);
