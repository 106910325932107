import React from "react";
import APIWrapper from "./APIWrapper";
import Search from "./Search";
import styles from "../styles/booking";
import CustomerForm from "./CustomerForm";

import withContext from "../utilities/withContext";

// component that handles POSTing a new exam appt to the api
// renders a search component that hits the customers endpoint to bring customer
// records back via elastic search

export class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.renderChildren = this.renderChildren.bind(this);
    this.handleSearchResultClick = this.handleSearchResultClick.bind(this);
    this.handleNewUserValidation = this.handleNewUserValidation.bind(this);
    this.setErrorState = this.setErrorState.bind(this);
    this.state = {
      showConfirmationButton: false,
      activeCustomer: {},
      error: null,
      customerCreated: false,
      customerSelected: false,
    };
  }

  componentWillUnmount() {
    const default_name = {
      name: "Please select an exam type",
      duration: 0,
      id: 0,
    };
    this.props.setExamTypeDropdown(default_name);
  }

  handleSearchResultClick(customer, e) {
    e.preventDefault();
    this.setState({
      showConfirmationButton: true,
      activeCustomer: customer,
    });
    this.props.setBookedCustomer(customer);
  }

  handleNewUserValidation(isValid, formState) {
    logger.info({ component: "Booking" }, "New user validation");
    // ensure that new user forms are valid before rendering create user button
    this.setState({
      customerCreated: false,
      showUserCreateButton: isValid,
      activeCustomer: {
        first_name: formState.first_name,
        last_name: formState.last_name,
        email: formState.email,
        telephone: formState.telephone,
        dob: formState.dob,
      }
    });
    this.props.setBookedCustomer({
      first_name: formState.first_name,
      last_name: formState.last_name,
    });
  }

  formatDOB(dob) {
    if(!dob) return null;
    const splitDOB = dob.split("-");
    return `${splitDOB[2]}-${splitDOB[0]}-${splitDOB[1]}`;
  }

  handlePayload(customerID) {
    const { activeCustomer } = this.state;
    const {
      exam_room,
      duration,
      profile,
      startTime,
    } = this.props;
    return {
      email: activeCustomer.email,
      first_name: activeCustomer.first_name,
      last_name: activeCustomer.last_name,
      date_of_birth: this.formatDOB(activeCustomer.dob),
      duration,
      exam_room,
      phone: activeCustomer.telephone,
      profile,
      scheduled_time: startTime,
      customer_id: activeCustomer.customer_id || customerID,
    };
  }

  handleOpts(payload) {
    return {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  setErrorState(error) {
    this.setState({ error });
  }

  handleNewCustomer(fetchData) {
    const { activeCustomer } = this.state;
    const { token, facilityShortname, doctor, handleNewAppointmentBooking } = this.props;
    const setPMPAppointment = (data) => {
      this.setState({ customerCreated: true });
      const payload = this.handlePayload(data.customer_id);
      const pmpOpts = this.handleOpts(payload);
      fetchData(`/offices/${facilityShortname}/doctors/${doctor}/appointments`, pmpOpts, handleNewAppointmentBooking, this.setErrorState);
    };
    const customer = {
      first_name: activeCustomer.first_name,
      last_name: activeCustomer.last_name,
      email: activeCustomer.email,
      telephone: activeCustomer.telephone,
    };
    const opts = {
      method: "POST",
      body: JSON.stringify(customer),
      headers: {
        Authorization: `bearer ${token}`
      }
    };
    const url = "/api/v2/customers";
    fetchData(url, opts, setPMPAppointment, this.setErrorState);
  }

  handleAppointmentConfirm(fetchData) {
    const { facilityShortname, doctor } = this.props;
    const { activeCustomer } = this.state;

    const payload = this.handlePayload();
    const opts = this.handleOpts(payload);

    if (!activeCustomer.customer_id) {
      this.handleNewCustomer(fetchData);
    } else {
      fetchData(`/offices/${facilityShortname}/doctors/${doctor}/appointments`, opts);
      this.setState({
        customerSelected: true,
      });
    }
    this.props.confirmBooking();
  }

  handleBlockClick(e) {
    const { target } = e;
    if (target.id === "booking-modal") {
      this.setState({ showConfirmationButton: false });
    }
  }

  renderChildren({ data, err, isFetching, fetchData }) {
    const {
      customerCreated,
      customerSelected,
      showConfirmationButton,
      showUserCreateButton,
      error
    } = this.state;
    const customerAndAppointmentCreated = customerCreated && this.props.appointmentCreated;
    const appointmentBooked = customerAndAppointmentCreated || customerSelected;
    return (
      <div
        {...styles.block}
        onClick={this.handleBlockClick.bind(this)}
        id="booking-modal"
      >
        <div {...styles.contentWrapper}>
          <div {...styles.title}> Book an Eye Exam </div>
          <Search handleSearchResultClick={this.handleSearchResultClick} />
          <button
            {...styles.button}
            children="close"
            onClick={this.props.handleBookingModalClose}
          />
          {showConfirmationButton && (
            <button
              children="Book appt"
              {...styles.button}
              onClick={this.handleAppointmentConfirm.bind(this, fetchData)}
            />
          )}
          {showUserCreateButton && (
            <button
              children="Create user and book"
              {...styles.button}
              onClick={this.handleAppointmentConfirm.bind(this, fetchData)}
            />
          )}
          {customerCreated && <h2 {...styles.customerCreated}>Customer created</h2>}
          {appointmentBooked && <><h2 {...styles.customerCreated}>Appointment created</h2><p>Sending you back to appointments ...</p></>}
          {(this.props.error || error) && (
            <h2 {...styles.error}> {error || "ERROR BOOKING APPT"} </h2>
          )}
        </div>
        {<CustomerForm handleFormValidation={this.handleNewUserValidation} />}
      </div>
    );
  }

  render() {
    const url = ``;
    return (
      <APIWrapper
        url={url}
        childFn={this.renderChildren}
        successCallback={this.props.handleNewAppointmentBooking}
        fetchOnMount={false}
      />
    );
  }
}

export default withContext(Booking);
