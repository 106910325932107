import { headingFontFamily } from "../utilities/styles";

const { css } = require("glamor");
const { bodyFontFamily, grayBorder, colors } = require("../utilities/styles");

export default {
  block: css({
    paddingBottom: "42px",
    fontFamily: bodyFontFamily,
    margin: "0 auto"
  }),
  loading: css({
    backgroundColor: "black",
    opacity: "0.5",
    position: "absolute",
    zIndex: "2",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    color: "white"
  }),
  topLineWrapper: css({
    border: grayBorder,
    backgroundColor: "white"
  }),
  label: css({
    fontSize: "12px",
    width: "25%",
    padding: "8px",
    fontWeight: "600",
    letterSpacing: "1px",
    textAlign: "left"
  }),
  labelTime: css({
    paddingLeft: "18px"
  }),
  datePickerWrapper: css({
    margin: "12px auto",
    textAlign: "center",
    backgroundColor: colors["light-gray-alt-2"],
    padding: "12px 0"
  }),
  inline: css({
    display: "inline"
  }),
  inlineBlock: css({
    display: "inline-block"
  }),
  button: css({
    border: 0,
    appearance: "none",
    fontSize: "16px",
    padding: "16px 32px",
    backgroundColor: colors["light-gray-alt-2"],
    color: "gray",
    margin: "0",
    "&:active": {
      backgroundColor: colors["light-gray-alt-2"]
    },
    "&:hover": {
      backgroundColor: colors["light-gray-alt-2"],
      color: "gray"
    },
    "&:visited": {
      backgroundColor: colors["light-gray-alt-2"]
    },
    "&:focus": {
      backgroundColor: colors["light-gray-alt-2"]
    }
  }),
  buttonLeft: css({
    "&:after": {
      content: "\\2190"
    }
  }),
  buttonRight: css({
    "&:after": {
      content: "\\2192"
    }
  }),
  dayOfWeek: css({
    fontSize: "14px",
    marginBottom: "0",
    fontFamily: headingFontFamily,
    fontWeight: "normal"
  }),
  date: css({
    fontSize: "24px",
    fontWeight: "600",
    letterSpacing: "1.5px",
    fontFamily: headingFontFamily,
    margin: "0"
  }),
  slots: css({
    margin: "0 60px 0 60px"
  }),
  pageTitle: css({
    margin: "24px 48px",
    fontFamily: bodyFontFamily,
    fontSize: "30px",
    fontWeight: "600"
  }),
  sideBySide: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  nearbyExamsButton: css({
    padding: "16px 32px 17px",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: bodyFontFamily,
    backgroundColor: "white",
    color: colors["dark-gray"],
    border: grayBorder,
    marginBottom: "20px"
  }),
  nearbyExamsButtonWrapper: css({
    textAlign: "center"
  }),
  loadingContent: css({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }),
  table: css({ width: "100%", borderCollapse: "collapse" }),
  examSelectError: css({
    textAlign: "center",
    display: "block",
    color: "red",
    margin: "0 48px",
    fontFamily: headingFontFamily,
    fontSize: "15px",
    fontWeight: 600,
  }),
  reloadIcon: css({
    padding: "0 10px",
    cursor: "pointer",
  }),
};
