import React from "react";
const moment = require("moment");
import styles from "../styles/booked";

import withContext from "../utilities/withContext";

const APPOINTMENT_TYPE_LOOKUP = {
  eye_exam: "Eye exam",
  dilation: "Dilation",
  employee_eye_exam: "Employee Eye Exam",
  dmv_exam: "DMV Exam",
  recheck: "Recheck",
  prism_follow_up: "Prism Follow Up",
  medical_exam: "Medical Exam"
};

class Booked extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckedIn: this.props.isCheckedIn,
      bookedCustomer: this.props.bookedCustomer,
    };
  }

  formatTime(ISOTime) {
    return moment(ISOTime).format("LT");
  }

  handleButtonToggle(fetchData) {
    const { token, appointment } = this.props;
    const url = appointment.update_status_url;

    fetchData(`${process.env.PMP_API}${url}`, {
      body: JSON.stringify({ status: "Arrived" }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`
      },
      method: "PATCH",
    });

    this.setState({ isCheckedIn: true });
  }

  renderStatusToggle(fetchData) {
      return (
        <button
          {...styles.button}
          onClick={this.handleButtonToggle.bind(this, fetchData)}
        >
          Mark arrived
        </button>
      );
  }

  renderStatusSlot(fetchData, status) {
    if (this.props.showConfirmation) {
      return <td {...styles.cell} children="Booked!" />;
    } else if (status === "Arrived" || this.state.isCheckedIn) {
      return <td {...styles.cell} children="Arrived" />;
    } else {
      return <td {...styles.cell}>{this.renderStatusToggle(fetchData)}</td>;
    }
  }

  renderBookedSlot() {
    const { isFetching, fetchData, bookedCustomer, appointment} = this.props;
    const strippedAppt = appointment && appointment.type && appointment.type.replace(/_/g, " ").toLowerCase().replace("comprehensive", " ");
    const blockClass = this.props.showConfirmation
      ? styles.blockConfirmation
      : styles.block;
    return (
      <tr {...blockClass}>
        {isFetching && <div {...styles.loading} />}
        <td
          {...styles.cell}
          {...styles.cellTime}
          children={this.formatTime(this.props.start_time)}
        />
        <td
          {...styles.cell}
          children={
            `${bookedCustomer ? bookedCustomer.first_name : appointment.first_name} ${bookedCustomer ? bookedCustomer.last_name : appointment.last_name}`
          }
        />
        <td
          {...styles.cell}
          children={
            bookedCustomer && bookedCustomer.currentAppointmentType ?
            bookedCustomer.currentAppointmentType : strippedAppt
          }
        />
        {this.renderStatusSlot(fetchData, appointment.status)}
      </tr>
    );
  }

  renderBookedNearbySlot() {
    const appointment = this.props.appointment || {};

    return (
      <tr {...styles.blockConfirmation}>
        <td
          {...styles.cell}
          {...styles.cellTime}
          children={this.formatTime(this.props.start_time)}
        />
        <td {...styles.cell} children={this.props.facilityName} />
        <td {...styles.cell}>
          <div children={appointment.first_name} />
          <div children={appointment.last_name} />
        </td>
        <td {...styles.cell} children={"Booked!"} />
      </tr>
    );
  }

  render() {
    const { isFetching, fetchData, nearby, showConfirmation } = this.props;
    if (nearby) {
      return this.renderBookedNearbySlot();
    } else {
      return this.renderBookedSlot();
    }
  }
}

export default withContext(Booked);
