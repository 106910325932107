import { css } from "glamor";

export default {
  block: css({
    marginBottom: "12px"
  }),
  resultWrapper: css({
    margin: "12px",
    border: "2px solid #00a2e1",
    textAlign: "left",
    color: "#00a2e1",
    padding: "12px",
    display: "block",
    "&:focus": {
      boxShadow: "1px 0px 8px #00a2e1"
    },
    "&:active": {
      boxShadow: "1px 0px 8px #00a2e1"
    },
    "&:hover": {
      boxShadow: "1px 0px 8px #00a2e1"
    }
  }),
  resultsList: css({
    maxHeight: "500px",
    overflow: "scroll"
  }),
  inputWrapper: css({
    marginBottom: "36px"
  }),
  searchInput: css({
    borderBottomColor: "#00a2e1",
    color: "green",
    borderRadius: 0,
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#a2aeb6",
    boxShadow: "none",
    color: "rgba(0,0,0,0.75)",
    display: "block",
    fontFamily: "inherit",
    fontSize: ".75rem",
    height: "2.1875rem",
    margin: "0 0 1rem 0",
    padding: ".5rem",
    width: "100%"
  })
};
