import { css } from "glamor";
const {
  colors,
} = require("../utilities/styles");

export default {
  block: css({
    position: "fixed",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    zIndex: "2000",
    backgroundColor: "white",
    overflow: "scroll"
  }),
  button: css({
    border: 0,
    margin: "8px",
    appearance: "none",
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: 0,
    cursor: "pointer",
    fontFamily: `"proxima-nova","Helvetica","Verdana",sans-serif`,
    fontWeight: 600,
    lineHeight: "normal",
    margin: "0 0 1.25rem",
    position: "relative",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    padding: "1rem 2rem 1.0625rem 2rem",
    fontSize: "1rem",
    backgroundColor: "#00a2e1",
    borderColor: "#00a2e1",
    color: "#fff",
    transition: "background-color 300ms ease-out"
  }),
  contentWrapper: css({
    textAlign: "center",
    width: "75%",
    margin: "5% auto"
  }),
  title: css({
    color: "#00a2e1",
    marginBottom: "36px"
  }),
  form: css({
    textAlign: "center",
    width: "75%",
    margin: "15% auto"
  }),
  customerCreated: css({
    color: colors["dark-gray"],
    textAlign: "center"
  }),
  error: css({
    color: "red",
    textAlign: "center"
  })
};
