import React from "react";
import { FormErrors } from "./FormErrors";
import styles from "../styles/customerForm";

import withContext from "../utilities/withContext";

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      telephone: "",
      dob: "",
      formErrors: {
        email: "",
        first_name: "",
        last_name: "",
        telephone: "",
        dob: "",
      },
      emailValid: false,
      firstNameValid: false,
      lastNameValid: false,
      formValid: false,
      telephoneValid: false,
      dobValid: false,
    };
  }

  handleUserInput(e) {
    // handle keystrokes, validating input and setting form values as state
    const name = e.target.name;
    const value = e.target.value;
    this.validateField(name, value);
  }

  validateDob(dob) {
    if (dob.length < 10) return false;

    const matches = /^(\d{1,2})[-](\d{1,2})[-](\d{4})$/.exec(dob);
    if (matches == null) return false;

    const d = matches[2];
    const m = matches[1] - 1;
    const y = matches[3];
    const composedDate = new Date(y, m, d);
    return composedDate.getDate() == d &&
      composedDate.getMonth() == m &&
      composedDate.getFullYear() == y;
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let {
      emailValid,
      firstNameValid,
      lastNameValid,
      telephoneValid,
      dobValid,
    } = this.state;

    switch (fieldName) {
      case "first_name":
        firstNameValid = value.length >= 2;
        fieldValidationErrors.first_name = firstNameValid
          ? ""
          : " is too short";
        break;
      case "last_name":
        lastNameValid = value.length >= 2;
        fieldValidationErrors.last_name = lastNameValid ? "" : " is too short";
        break;
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;
      case "telephone":
        telephoneValid = value.length === 10;
        fieldValidationErrors.telephone = telephoneValid ? "" : " is invalid";
        break;
      case "dob":
        dobValid = this.validateDob(value);
        fieldValidationErrors.dob = dobValid ? "" : " is invalid";
        break;
      default:
        break;
    }
    const formValid = emailValid && firstNameValid && lastNameValid && telephoneValid && dobValid;
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        firstNameValid: firstNameValid,
        lastNameValid: lastNameValid,
        telephoneValid: telephoneValid,
        dob: dobValid,
        [fieldName]: value,
        formValid: formValid,
      },
      () => this.props.handleFormValidation(formValid, this.state),
    );
  }

  render() {
    return (
      <div>
        <div>
          <FormErrors formErrors={this.state.formErrors} />
        </div>
        <form {...styles.form}>
          <h2> Create a customer </h2>
          <div {...styles.formGroup}>
            <label htmlFor="first_name"> First name </label>
            <input
              type="text"
              {...styles.formControl}
              name="first_name"
              value={this.state.first_name}
              onChange={this.handleUserInput.bind(this)}
            />
          </div>
          <div {...styles.formGroup}>
            <label htmlFor="last_name"> Last name </label>
            <input
              type="text"
              {...styles.formControl}
              name="last_name"
              value={this.state.last_name}
              onChange={this.handleUserInput.bind(this)}
            />
          </div>
          <div {...styles.formGroup}>
            <label htmlFor="email"> Email </label>
            <input
              type="email"
              {...styles.formControl}
              name="email"
              value={this.state.email}
              onChange={this.handleUserInput.bind(this)}
            />
          </div>
          <div {...styles.formGroup}>
            <label htmlFor="telephone"> Telephone (format: xxxxxxxxxx) </label>
            <input
              type="text"
              {...styles.formControl}
              name="telephone"
              value={this.state.telephone}
              onChange={this.handleUserInput.bind(this)}
            />
          </div>
          <div {...styles.formGroup}>
            <label htmlFor="dob"> Date of birth (format: MM-DD-YYYY) </label>
            <input
              type="text"
              {...styles.formControl}
              name="dob"
              value={this.state.dob || ""}
              onChange={this.handleUserInput.bind(this)}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default withContext(CustomerForm);
