const { css } = require("glamor");
const {
  bodyFontFamily,
  headingFontFamily,
  grayBorder,
  colors,
} = require("../utilities/styles");

export default {
  block: css({
    paddingBottom: "42px",
    fontFamily: bodyFontFamily,
    margin: "0 60px 0 60px",
  }),
  sectionWrapper: css({
    maxHeight: "600px",
    overflow: "auto",
    borderBottom: grayBorder,
  }),
  title: css({
    textAlign: "center",
    fontSize: "30px",
    fontFamily: grayBorder,
    fontWeight: "600",
  }),
  label: css({
    fontSize: "12px",
    width: "25%",
    padding: "6px 12px",
    fontWeight: "600",
    letterSpacing: "1px",
    textAlign:"left"
  }),
  labelTime: css({
    paddingLeft: "12px",
  }),
  topLineWrapper: css({
    border: grayBorder,
    backgroundColor: "white",
  }),
  closeButton: css({
    backgroundColor: "white",
    color: colors["dark-gray"],
    border: grayBorder,
    fontSize: "16px",
    padding: "16px 32px",
    fontWeight: 600
  }),
  closeButtonWrapper: css({
    textAlign: "center",
  }),
  date: css({
    fontSize: "24px",
    marginBottom: "18px",
  }),
  table: css({
    width: "100%",
    borderCollapse: "collapse"
  })
};
