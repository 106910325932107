export const colors = {
  blue: "#00a2e1",
  "blue-highlight": "#4dbdea",
  "blue-shadow": "#0089bf",
  "blue-cta": "#0caded",

  "dark-gray": "#414b56",
  "dark-gray-alt-1": "#6b7b8c",
  "dark-gray-alt-2": "#a1a4a6",
  "dark-gray-alt-3": "#676f78",
  "dark-gray-alt-4": "#2b343e",

  "light-gray": "#d2d6d9",
  "light-gray-alt-1": "#e1e5e6",
  "light-gray-alt-2": "#f8f8f8",
  "light-gray-alt-3": "#fcfcfc",
  "light-gray-alt-4": "#525e69",
  "light-gray-alt-5": "#eff3f5",
  "light-gray-alt-6": "#A0A0A0",
  "light-gray-alt-7": "#f6f6f6",
};

export const grayBorder = "1px solid rgba(161, 164, 166, 0.625)";

export const bodyFontFamily =
  '"proxima-nova", "Helvetica", "Verdana", sans-serif';
export const headingFontFamily =
  '"utopia-std-display", "utopia-std", "Georgia", serif';
