import { css } from "glamor";

export default {
  block: css({}),
  form: css({
    textAlign: "center",
    width: "75%",
    margin: "50px auto"
  }),
  formGroup: css({
    width: "50%",
    margin: "0 auto"
  }),
  formControl: css({
    borderRadius: 0,
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#a2aeb6",
    boxShadow: "none",
    color: "rgba(0,0,0,0.75)",
    display: "block",
    fontFamily: "inherit",
    fontSize: ".75rem",
    height: "2.1875rem",
    margin: "0 0 1rem 0",
    padding: ".5rem",
    width: "100%"
  })
};
