const React = require("react");
const ReactDOM = require("react-dom");
const PropTypes = require("prop-types");
const { bodyFontFamily, colors } = require("../utilities/styles");
const { css } = require("glamor");

import withContext from "../utilities/withContext";

const styles = {
  block: css({
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "left",
    justifyContent: "left",
    cursor: "default",
  }),
  contents: css({
    width: "20em",
    zIndex: "10000",
    position: "absolute",
    top: "100%",
    left: "0",
    background: colors["white"],
    border: `1px solid ${colors["light-gray"]}`,
    boxShadow: `0 2px 4px 1px rgba(0, 0, 0, 0.12)`,
    padding: "0",
    listStyle: "none",
    marginLeft: 0,
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "36em",
    //Make the scrollbar always visible
    "::Webkitscrollbar": {
      "Webkitappearance": "none",
      width: "7px",
    },
    "::Webkitscrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "rgba(0,0,0,.5)",
      WebkitBoxshadow: "0 0 1px rgba(255,255,255,.5)",
    },
  }),
  item: css({
    padding: "0.5em",
    fontSize: "14px",
    fontFamily: bodyFontFamily,
    backgroundColor: colors["light-gray-alt-2"],
    borderBottom: `1px ${colors["light-gray-alt-1"]} solid`,
    fontWeight: 600,
    ":hover": {
      backgroundColor: colors["light-gray-alt-1"],
    },
    ":active": {
      backgroundColor: colors["light-gray-alt-1"],
    },
  }),
  collapsedHeader: css({
    "::after": {
      content: "\u00A0⌄",
    },
  }),
  expandedHeader: css({
    "::after": {
      content: "⌄\u00A0",
      transform: "rotate(180deg) translateY(-0.1em)",
      display: "inline-block",
    },
  }),
  buttonStyleHeader: css({
    position: "relative",
    width: "100%",
    padding: "12px 24px 12px 12px",
    background: colors["white"],
    border: `1px solid ${colors["light-gray"]}`,
    color: colors["dark-gray"],
    fontSize: "14px",
    fontFamily: bodyFontFamily,
    fontWeight: 600,
    "::after": {
      position: "absolute",
      right: "8px",
    },
  }),
};

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      selectedKey:
        this.props.initiallySelectedKey || this.props.elements[0].key,
    };
  }
  componentDidMount() {
    this.boundClickHandler = this.handleGlobalClick.bind(this);
    window.addEventListener("mouseup", this.boundClickHandler);
  }
  componentWillUnmount() {
    window.removeEventListener("mouseup", this.boundClickHandler);
  }
  handleGlobalClick(evt) {
    if (!ReactDOM.findDOMNode(this).parentNode.contains(evt.target)) {
      this.collapse();
    }
  }
  lookupKey(key) {
    return this.props.elements
      .filter(el => el.key == key)
      .map(el => el.value)[0];
  }
  expand(e) {
    this.setState({ expanded: true });
  }
  collapse(e) {
    this.setState({ expanded: false });
  }
  select(key) {
    this.setState({
      selectedKey: key,
      expanded: false,
    });
    logger.info({ component: "Dropdown",  key: key }, "Facility selected");
    this.props.onChange(key);
  }
  renderExpanded() {
    // TODO: this conditional styling and the similar one in renderCollapsed() are here
    // because I haven't yet decided on a way to approach props-based or state-based
    // styling with glamor. Might switch to emotion.sh or another framework, or do something
    // homegrown that's simpler.
    const headerStyle = this.props.useButtonStyling
      ? css(styles.expandedHeader, styles.buttonStyleHeader)
      : styles.expandedHeader;
    return (
      <div {...styles.block}>
        <div
          {...headerStyle}
          onClick={() => this.collapse()}
          children={
            this.lookupKey(this.state.selectedKey) || this.props.placeHolder
          }
        />
        <ul {...styles.contents}>
          {this.props.elements.map((el, i) => {
            return (
              <li
                key={`option-${i}`}
                onClick={e => this.select(el.key)}
                children={el.value}
                {...styles.item}
              />
            );
          })}
        </ul>
      </div>
    );
  }
  renderCollapsed() {
    const headerStyle = this.props.useButtonStyling
      ? css(styles.collapsedHeader, styles.buttonStyleHeader)
      : styles.collapsedHeader;
    return (
      <div {...styles.block} onClick={() => this.expand()}>
        <div {...headerStyle}>
          {this.lookupKey(this.state.selectedKey) || this.props.placeHolder}
        </div>
      </div>
    );
  }

  render() {
    return this.state.expanded ? this.renderExpanded() : this.renderCollapsed();
  }
}

Dropdown.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
  ),
  initiallySelectedKey: PropTypes.string,
  onChange: PropTypes.func,
  useButtonStyling: PropTypes.bool,
};
Dropdown.defaultProps = {
  elements: [],
  initiallySelectedKey: null,
  placeholder: "Select",
  onChange: (key, value) => {},
  useButtonStyling: true,
};

export default withContext(Dropdown);
