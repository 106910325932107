const React = require("react");
const { render } = require("react-dom");
import "whatwg-fetch";
import getUrl from "../utilities/getUrl";
import withContext from "../utilities/withContext";

// An API Wrapper class. Expects a function as its children, passes
// API responses/errors to that function
class APIWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      err: null,
      isFetching: false
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    if (this.props.fetchOnMount) {
      this.fetchData();
    }
  }

  componentWillUpdate(nextProps) {
    // Check for incoming URL changes
    // If the URL to be fetched has changed, be sure to
    // fetch the incoming one, not the current one.
    if (nextProps.url !== this.props.url && this.props.fetchOnMount) {
      this.fetchData(nextProps.url);
    }
  }

  async fetchData(
    url = this.props.url,
    opts = this.props.opts,
    successCallback = this.props.successCallback,
    setErrorState,
  ) {
    logger.info({ component: "APIWrapper", url: url, opts: opts }, `FETCHING ...`);
    if (this.state.isFetching) return;
    this.setState({ isFetching: true });

    opts.headers = opts.headers || {};
    if (opts.credentials === "include" && !opts.headers.Authorization) {
      opts.headers.Authorization = `bearer ${this.props.token}`;
    }

    try {
      const resp = await fetch(getUrl(url), opts);
      if (!resp.ok) {
        const errorMsg = resp.statusText || "User not logged in";
        if (setErrorState) {
          const err = await resp.json();
          setErrorState(err.error.message);
        }
        logger.error({ component: "APIWrapper", msg: errorMsg });
        throw Error(errorMsg);
      }
      const data = await resp.json();
      logger.info({ component: "APIWrapper", url: url }, "Success!");
      this.setState({
        data: data,
        isFetching: false
      });

      if (successCallback) {
        successCallback(data);
      }
      if (setErrorState) {
        setErrorState(null);
      }
    } catch (err) {
      this.setState({ err, isFetching: false });
      logger.error({ component: "APIWrapper", err: err }, "fetchData(): Something went wrong...");
    }
  }

  render() {
    const { data, err, isFetching } = this.state;
    return this.props.childFn({
      data,
      err,
      isFetching,
      fetchData: this.fetchData
    });
  }
}

export default withContext(APIWrapper);
