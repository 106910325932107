const { css } = require("glamor");
const {
  bodyFontFamily,
  headingFontFamily,
  grayBorder,
  colors
} = require("../utilities/styles");

export default {
  button: css({
    margin: "0",
    padding: "6px 22px",
    borderRadius: "14px",
    fontSize: "15px",
    fontWeight: "200",
    backgroundColor: colors["blue-cta"],
    color: "#fff",
    border: 0
  }),
  cell: css({
    fontSize: "15px",
    fontFamily: headingFontFamily,
    width: "25%",
    padding: "18px 12px"
  }),
  block: css({
    padding: "16px 0",
    fontFamily: bodyFontFamily,
    margin: "0 auto",
    width: "100%",
    backgroundColor: "white",
    borderBottom: grayBorder,
    borderLeft: grayBorder,
    borderRight: grayBorder,
    position: "relative"
  }),
  blockConfirmation: css({
    backgroundColor: "#00a2e1",
    color: "white",
    padding: "16px 0",
    fontFamily: bodyFontFamily,
    margin: "0 auto",
    width: "100%",
    position: "relative"
  }),
  loading: css({
    backgroundColor: "black",
    opacity: "0.5",
    position: "absolute",
    zIndex: "2",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0"
  }),
  cellTime: css({
    paddingLeft: "12px"
  })
};
