const { css } = require("glamor");
const {
  bodyFontFamily,
  headingFontFamily,
  grayBorder,
  colors
} = require("../utilities/styles");

export default {
  block: css({
    padding: "16px 0",
    fontFamily: bodyFontFamily,
    margin: "0 auto",
    width: "100%",
    position: "relative",
    backgroundColor: "white",
    borderBottom: grayBorder,
    borderLeft: grayBorder,
    borderRight: grayBorder
  }),
  cell: css({
    fontSize: "15px",
    fontFamily: headingFontFamily,
    width: "25%",
    padding: "12px",
  }),
  cellTime: css({
    paddingLeft: "12px"
  }),
  bookButton: css({
    margin: "0",
    padding: "6px 22px",
    border: 0,
    borderRadius: "14px",
    fontSize: "15px",
    fontWeight: "200",
    backgroundColor: colors["blue-cta"],
    color: "#fff"
  }),
  disabledBookButton: css({
    margin: "0",
    padding: "6px 22px",
    border: 0,
    borderRadius: "14px",
    fontSize: "15px",
    fontWeight: "200",
    backgroundColor: colors["light-gray"],
    color: "#fff"
  })
};
