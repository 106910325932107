const React = require("react");
const { render } = require("react-dom");

import PoeLayout from "@warbyparker/poe-layout";
import Context from "./components/Context";
import EyeExamList from "./components/EyeExamList";

class App extends React.Component {
  state = {
    facility: null
  };

  setFacility = facility => {
    this.setState({ facility });
  };

  getFacility({ facility }) {
    return this.state.facility || facility;
  }

  render() {
    return (
      <PoeLayout
        domain={process.env.HELIOS_RETAIL_DOMAIN}
        urlHome={process.env.POE_HOME_URL}
      >
        {({ token, err, Header, me = {} }) => {
          const facility = this.getFacility(me);
          return (
            <div>
              {token && me.retail_stores && facility ? (
                <Context.Provider
                  value={{
                    token,
                    facility,
                    setFacility: this.setFacility,
                  }}
                >
                  <main>
                    <Header />

                    {me.retail_stores.length > 0 ? (
                      <EyeExamList
                        userFacilities={me.retail_stores}
                        defaultFacility={me.facility}
                      />
                    ) : (
                      <h1>Not assigned to any stores</h1>
                    )}
                  </main>
                </Context.Provider>
              ) : (
                <h1>{`${err ? err : "Loading"}`}</h1>
              )}
            </div>
          );
        }}
      </PoeLayout>
    );
  }
}

render(<App />, document.getElementById("app"));
