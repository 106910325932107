import React from "react";
import { render } from "react-dom";
import APIWrapper from "./APIWrapper";
import styles from "../styles/search";
// consider moving this to a prop
const BASE_URL = "/api/v1/customers/search?q=";

import withContext from "../utilities/withContext";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.renderChildren = this.renderChildren.bind(this);
    this.state = {
      searchVal: ""
    };
  }
  handleChange(e) {
    const val = e.target.value;
    this.setState({ searchVal: val });
  }

  renderResults(data = []) {
    const children = data.map((account, i) => {
      return (
        <div
          {...styles.resultWrapper}
          key={i}
          onClick={this.props.handleSearchResultClick.bind(this, account)}
        >
          <div>
            <span> {account.first_name} </span>
            <span> {account.last_name} </span>
          </div>
          <div> {account.email} </div>
          <div> {account.telephone} </div>
        </div>
      );
    });

    return <div {...styles.resultsList} children={children} />;
  }
  renderChildren({ data, err, isFetching, fetchData }) {
    return (
      <div {...styles.block}>
        <div {...styles.inputWrapper}>
          <input
            name="search_email"
            placeholder={"Enter an email address or phone number"}
            {...styles.searchInput}
            onChange={this.handleChange.bind(this)}
          />
        </div>
        {this.renderResults(data)}
      </div>
    );
  }

  render() {
    return (
      <div>
        <APIWrapper
          url={`${BASE_URL}${this.state.searchVal}`}
          fetchOnMount={true}
          opts={{ credentials: "include" }}
          childFn={this.renderChildren}
        />
      </div>
    );
  }
}

export default withContext(Search);
