export default path => {
  if (path.indexOf("/api/v1/") < 0 && path.indexOf("/offices/") >= 0) {
    logger.info({ host: process.env.PMP_API, msg: path }, "PMP endpoint called");
    return `${process.env.PMP_API}${path}`;
  }
  if (path.indexOf("https://") === 0) {
    logger.info({ host: process.env.HELIOS_RETAIL_DOMAIN, msg: path }, "HELIOS endpoint called");
    return path;
  }
  return `https://${process.env.HELIOS_RETAIL_DOMAIN}${path}`;
};
