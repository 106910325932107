import React from "react";
import Context from "../components/Context";

const withContext = Component => {
  return props => (
    <Context.Consumer>
      {context => <Component {...props} {...context} />}
    </Context.Consumer>
  );
};
export default withContext;
