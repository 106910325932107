const React = require("react");
const moment = require("moment");
import { find, flatten, groupBy, map } from "lodash";

import AppointmentSlot from "./AppointmentSlot";
import APIWrapper from "./APIWrapper";
import Dropdown from "./Dropdown";
import ExamTypesDropdown from "./ExamTypesDropdown";
import NearbyExamList from "./NearbyExamList";
import RightHeaderCorner from "./RightHeaderCorner";

import styles from "../styles/eyeExamList";

import withContext from "../utilities/withContext";

class EyeExamList extends React.Component {
  constructor(props) {
    super(props);
    this.reminderListener = this.reminderListener.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
    this.ohmRenderChildren = this.ohmRenderChildren.bind(this);
    this.setExamTypeDropdown = this.setExamTypeDropdown.bind(this);
    this.state = {
      activeDate: moment(),
      allAppointmentTypes: null,
      showNearbyExams: false,
      exam_id: null,
      exam_duration: null,
      exam_name: null,
      error: null,
      showDropdownReminder: false,
    };
  }

  setExamTypeDropdown = exam => {
    this.setState({
      exam_name: exam.name,
      exam_duration: exam.duration,
      exam_id: exam.id,
      showDropdownReminder: false,
    });
  };

  reminderListener() {
    const { exam_name } = this.state;
    const appointmentNotSelected = exam_name === "Please select an exam type";
    if (appointmentNotSelected) {
      this.setState({ showDropdownReminder: true });
    }
  }

  setDefaultExamType = data => {
    if (data.error) {
      logger.error({ component: "EyeExamList", err: data.error });
      this.setState({ error: data.error });
    } else {
      const default_name = {
        name: "Please select an exam type",
        duration: 0,
        id: 0,
      };
      logger.info({ component: "EyeExamList" }, "Setting default exam type");
      const { appointment_profiles } = data;
      this.setExamTypeDropdown(default_name);
      this.setState({ allAppointmentTypes: appointment_profiles })
    }
  };

  appointmentTypeLookup = appointmentID => {
    const { allAppointmentTypes } = this.state;
    if (!allAppointmentTypes) return "";
    const match = allAppointmentTypes.find(examType => examType.id === appointmentID) || {};
    return match.name;
  };

  handleDropdownChange(short_name) {
    const facility = find(this.props.userFacilities, { short_name });
    this.props.setFacility(facility);
  }

  handleExamTypesDropdownChange(exam) {
    this.setExamTypeDropdown(exam);
  }

  handleNewAppointmentBooking(data) {
    if (data.error) {
      this.setState({
        appointmentBookingError: data.error
      });
    } else {
      this.setState({ activeAppointment: data, showConfirmation: true, appointmentCreated: true, showBookingModal: false });
    }
  }

  renderAppointments(currentSlots, isLoading) {
    const apptChildren = currentSlots.map((slot, i) => {
      const key = `${this.props.facility.short_name}_${slot.start_time}_${i}`;
      return (
        <AppointmentSlot
          {...slot}
          exam_room={slot.exam_room}
          doctor={slot.doctor}
          duration={this.state.exam_duration}
          profile={this.state.exam_id}
          interval={i}
          key={key}
          token={this.props.token}
          handleNewAppointmentBooking={this.handleNewAppointmentBooking}
          facilityShortname={this.props.facility.short_name}
          currentAppointmentType={this.state.exam_name}
          reminderListener={this.reminderListener}
          isLoading={isLoading}
          setExamTypeDropdown={this.setExamTypeDropdown}
        />
      );
    });
    return (
      <table {...styles.table}>
        <thead {...styles.topLineWrapper}>
          <tr>
            <th {...styles.label} {...styles.labelTime}>
              {" "}
              TIME{" "}
            </th>
            <th {...styles.label}> CUSTOMER </th>
            <th {...styles.label}> TYPE </th>
            <th {...styles.label}> STATUS </th>
          </tr>
        </thead>
        <tbody {...styles.slotWrapper} children={apptChildren} />
      </table>
    );
  }

  togglerNearbyExams() {
    logger.info({ component: "EyeExamList" }, "Toggling nearby exams");
    this.setState({ showNearbyExams: !this.state.showNearbyExams });
  }

  renderNearbyExamsButton() {
    return (
      <div {...styles.nearbyExamsButtonWrapper}>
        <button
          {...styles.nearbyExamsButton}
          onClick={this.togglerNearbyExams.bind(this)}
          children={"SHOW NEARBY EXAMS"}
        />
      </div>
    );
  }

  renderLoadingModal() {
    logger.info({ component: "EyeExamList" }, "LOADING...");
    return (
      <div {...styles.loading}>
        <div {...styles.loadingContent}> LOADING </div>
      </div>
    );
  }



  handleOpenSlots(data) {
    let table;
    table =  data.open_slots &&
      data.open_slots[0] &&
      data.open_slots[0].time_slots.filter(slot => {
        const hasAppt = slot.appointment;
        const slotKeys = hasAppt && Object.keys(slot.appointment);
        const hasPatientKey = hasAppt && slotKeys.includes("patient");
        const hasPatient = hasAppt && hasAppt.patient;
        /*
        slots can either be:
        1. timeblock: time blocks have a patient key that is NULL
        2. open slot: open slots do not have a patient key
        in order to differentiate, we check if patient key is present in
        the apppointments object and further check if the patient key is null
        */
        const timeBlock = hasAppt && hasPatientKey && !hasPatient;
        const openSlot = !hasAppt;
        if (!timeBlock || openSlot) {
          return slot;
        }
      }).map(slot => {
        const { activeDate, exam_duration } = this.state;
        const startTime = `${activeDate.format("YYYY-MM-DD")}T${slot.time}:00`;
        const out = {
          start_time: startTime,
          end_time: moment(startTime)
            .add(exam_duration, "minutes")
            .toDate(),
          doctor: slot.appointment ? slot.appointment.doctor : slot.doctor,
          exam_room: slot.appointment ? null : slot.exam_room,
          duration: exam_duration,
          appointment: slot.appointment
            ? {
                email: slot.appointment.patient.email,
                first_name: slot.appointment.patient.first_name,
                id: slot.appointment.id,
                last_name: slot.appointment.patient.last_name,
                status: slot.appointment.status,
                type: this.appointmentTypeLookup(slot.appointment.profile),
                update_status_url: `/doctors/${slot.appointment.doctor}/appointments/${slot.appointment.id}`
              }
            : null
        };
        return out;
      });
    return { table };
  }

  // ohmRenderChildren calls renderChildren with translated ohm data.
  ohmRenderChildren({ data, err, isFetching, fetchData }) {
    data = this.handleOpenSlots(data);
    return this.renderChildren({ data, err, isFetching, fetchData });
  }

  renderChildren({ data, err, isFetching, fetchData }) {
    const { facility: { short_name } } = this.props;
    const allSlots = data.table || [];

    const currentSlots = _.compact(
      allSlots.map(slot => {
        const cutOff = moment().add(-0.5, "hours");
        const startTime = moment(slot.start_time);
        if (startTime.isAfter(cutOff)) {
          return slot;
        }
      })
    );
    const nearbyExamsURL = `/api/v1/appointments/nearby/${short_name}`;
    const appointmentProfilesURL = `/offices/${short_name}?show_appointment_profiles`;

    const apiURL = this.getAPIUrl();
    const { userFacilities } = this.props;
    return (
      <div {...styles.block}>
        {isFetching && this.renderLoadingModal()}
        {!this.state.showNearbyExams && (
          <div>
            <h1 className={`${styles.pageTitle} ${styles.sideBySide}`}>
              <Dropdown
                elements={userFacilities.map(
                  ({ short_name: key, name: value }) => ({ key, value })
                )}
                useButtonStyling={false}
                initiallySelectedKey={this.props.facility.short_name}
                onChange={this.handleDropdownChange.bind(this)}
              />
              <RightHeaderCorner url={apiURL} fetchData={fetchData}/>
            </h1>
            <div>
              <APIWrapper
                url={appointmentProfilesURL}
                fetchOnMount={true}
                opts={{ headers: {}, method: "GET" }}
                childFn={this.renderAppointmentTypesDropdown.bind(this)}
                successCallback={this.setDefaultExamType}
              />
            </div>
            {this.state.showDropdownReminder &&
              (<p {...styles.examSelectError}>Please select an exam type to book</p>)
            }
            {this.renderDatePicker()}
            <div {...styles.slots}>
              {currentSlots && this.renderAppointments(currentSlots, isFetching)}
            </div>
          </div>
        )}
        {this.state.showNearbyExams && (
          <div>
            <APIWrapper
              url={nearbyExamsURL}
              fetchOnMount={true}
              opts={{ credentials: "include" }}
              childFn={this.renderNearbyExamsChildren.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }

  handleNearbyEyeExamClose() {
    this.setState({ showNearbyExams: !this.state.showNearbyExams });
  }

  prepareNearbyExamData(data) {
    // Create an object of open exam slots from API, keyed by date
    if (data.length <= 0) return;
    const nearbyStores = data.nearby_stores;
    const dates = flatten(map(nearbyStores, "exams"));
    const slots = flatten(map(dates, "slots"));
    return groupBy(slots, "date");
  }

  renderNearbyExamsChildren({ data, err, isFetching, fetchData }) {
    if (!isFetching) {
      const groupedData = this.prepareNearbyExamData(data);
      return (
        <NearbyExamList
          exams={groupedData}
          handleModalClose={this.handleNearbyEyeExamClose.bind(this)}
          selectedExam={this.state.exam_name}
        />
      );
    } else {
      return this.renderLoadingModal();
    }
  }

  renderAppointmentTypesDropdown({ data, err, isFetching, fetchData }) {
    if (!isFetching && data && data.appointment_profiles) {
      const { appointment_profiles } = data;
      if (this.state.exam_name) {
        return (
          <h1 {...styles.pageTitle}>
            <ExamTypesDropdown
              appointmentProfiles={appointment_profiles}
              useButtonStyling={false}
              onChange={this.handleExamTypesDropdownChange.bind(this)}
              initiallySelectedExam={this.state.exam_name}
            />
          </h1>
        );
      } else {
        return <h1>Loading...</h1>
      }
    } else {
      return <h1 {...styles.pageTitle}>No exam types available</h1>
    }
  }

  handleDateIncrement() {
    this.setState({ activeDate: this.state.activeDate.add(1, "days") });
  }

  handleDateDecrement() {
    this.setState({ activeDate: this.state.activeDate.add(-1, "days") });
  }

  renderDatePicker() {
    return (
      <div {...styles.datePickerWrapper}>
        <button
          {...styles.inline}
          {...styles.button}
          {...styles.buttonLeft}
          onClick={this.handleDateDecrement.bind(this)}
        />
        <div {...styles.inlineBlock}>
          <h2
            {...styles.dayOfWeek}
            children={this.state.activeDate.format("dddd")}
          />
          <h2
            {...styles.date}
            children={this.state.activeDate.format("MMMM Do")}
          />
        </div>
        <button
          {...styles.inline}
          {...styles.button}
          {...styles.buttonRight}
          onClick={this.handleDateIncrement.bind(this)}
        />
      </div>
    );
  }

  getAPIUrl() {
    // TODO: Use Ohm for development environments too.
    const { activeDate, exam_id } = this.state;
    const { facility: { short_name } } = this.props;
    const date = activeDate.format("YYYY-MM-DD");
    const examIdQuery = exam_id ? `&profile=${exam_id}` : "";
    return `/offices/${short_name}/open-slots?show-appointments&show-current&date=${date}${examIdQuery}`;
  }

  render() {
    if (!this.props.facility) return false;
    return (
      <>
        <APIWrapper
          url={this.getAPIUrl()}
          fetchOnMount={true}
          opts={{
            headers: {
              //work around underscore header value bug; ohm accepts "underscore"
              Authorization: `bearer ${this.props.token.replace(
                /_/g,
                "underscore"
              )}`
            }
          }}
          key={this.props.facility.short_name}
          childFn={this.ohmRenderChildren}
        />
      </>
    );
  }
}

EyeExamList.defaultProps = {};

export default withContext(EyeExamList);
