import React from "react";
import styles from "../styles/eyeExamList";
import Reload from "../icons/reload.svg";

class RightHeaderCorner extends React.PureComponent {
  handleClick = () => {
    this.props.fetchData(this.props.url);
  };

  render() {
    return (
      <span className={`${styles.reloadIcon}`}>
        <img onClick={this.handleClick} src={Reload} alt="Reload"/>
      </span>
    );
  }
}

export default RightHeaderCorner;